// Generated by Haxe 4.1.4
(function ($global) { "use strict";
var $estr = function() { return js_Boot.__string_rec(this,''); },$hxEnums = $hxEnums || {},$_;
function $extend(from, fields) {
	var proto = Object.create(from);
	for (var name in fields) proto[name] = fields[name];
	if( fields.toString !== Object.prototype.toString ) proto.toString = fields.toString;
	return proto;
}
var HxOverrides = function() { };
HxOverrides.__name__ = true;
HxOverrides.remove = function(a,obj) {
	var i = a.indexOf(obj);
	if(i == -1) {
		return false;
	}
	a.splice(i,1);
	return true;
};
HxOverrides.now = function() {
	return Date.now();
};
var Lambda = function() { };
Lambda.__name__ = true;
Lambda.has = function(it,elt) {
	var x = $getIterator(it);
	while(x.hasNext()) {
		var x1 = x.next();
		if(x1 == elt) {
			return true;
		}
	}
	return false;
};
Lambda.exists = function(it,f) {
	var x = $getIterator(it);
	while(x.hasNext()) {
		var x1 = x.next();
		if(f(x1)) {
			return true;
		}
	}
	return false;
};
Lambda.foreach = function(it,f) {
	var x = $getIterator(it);
	while(x.hasNext()) {
		var x1 = x.next();
		if(!f(x1)) {
			return false;
		}
	}
	return true;
};
Math.__name__ = true;
var Std = function() { };
Std.__name__ = true;
Std.string = function(s) {
	return js_Boot.__string_rec(s,"");
};
var haxe_Exception = function(message,previous,native) {
	Error.call(this,message);
	this.message = message;
	this.__previousException = previous;
	this.__nativeException = native != null ? native : this;
};
haxe_Exception.__name__ = true;
haxe_Exception.caught = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value;
	} else if(((value) instanceof Error)) {
		return new haxe_Exception(value.message,null,value);
	} else {
		return new haxe_ValueException(value,null,value);
	}
};
haxe_Exception.thrown = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value.get_native();
	} else if(((value) instanceof Error)) {
		return value;
	} else {
		var e = new haxe_ValueException(value);
		return e;
	}
};
haxe_Exception.__super__ = Error;
haxe_Exception.prototype = $extend(Error.prototype,{
	unwrap: function() {
		return this.__nativeException;
	}
	,get_native: function() {
		return this.__nativeException;
	}
});
var haxe_ValueException = function(value,previous,native) {
	haxe_Exception.call(this,String(value),previous,native);
	this.value = value;
};
haxe_ValueException.__name__ = true;
haxe_ValueException.__super__ = haxe_Exception;
haxe_ValueException.prototype = $extend(haxe_Exception.prototype,{
	unwrap: function() {
		return this.value;
	}
});
var haxe_iterators_ArrayIterator = function(array) {
	this.current = 0;
	this.array = array;
};
haxe_iterators_ArrayIterator.__name__ = true;
haxe_iterators_ArrayIterator.prototype = {
	hasNext: function() {
		return this.current < this.array.length;
	}
	,next: function() {
		return this.array[this.current++];
	}
};
var hyperfiddle_hx_Origin = function() { };
hyperfiddle_hx_Origin.__name__ = true;
hyperfiddle_hx_Origin.get = function() {
	if(hyperfiddle_hx_Origin.main != null) {
		return hyperfiddle_hx_Origin.main;
	} else {
		return hyperfiddle_hx_Origin.main = new hyperfiddle_hx_Flow();
	}
};
hyperfiddle_hx_Origin.all = function(f) {
	hyperfiddle_hx_Origin.get().all(f);
};
hyperfiddle_hx_Origin.onError = function(error) {
	console.log("./src/hyperfiddle/hx/Dataflow.hx:14:",error);
};
hyperfiddle_hx_Origin.input = function(f) {
	var end = null;
	return new hyperfiddle_hx_Input(hyperfiddle_hx_Origin.get(),new hyperfiddle_hx_Push(null,hyperfiddle_hx_NodeDef.From({ on : function() {
		if(f != null) {
			end = f();
		}
	}, off : function() {
		if(end != null) {
			end();
			end = null;
		}
	}})));
};
hyperfiddle_hx_Origin.on = function(v,f) {
	var out = new hyperfiddle_hx_Output(hyperfiddle_hx_Origin.get(),new hyperfiddle_hx_Push([v.node],hyperfiddle_hx_NodeDef.Into(f)));
	out.init();
	return out;
};
hyperfiddle_hx_Origin.apply = function(ns,f) {
	var tmp = hyperfiddle_hx_Origin.get();
	var _g = [];
	var _g1 = 0;
	while(_g1 < ns.length) {
		var n = ns[_g1];
		++_g1;
		_g.push(n.node);
	}
	return new hyperfiddle_hx_View(tmp,new hyperfiddle_hx_Push(_g,hyperfiddle_hx_NodeDef.ApplyN(f)));
};
hyperfiddle_hx_Origin.applyAsync = function(ns,f) {
	var tmp = hyperfiddle_hx_Origin.get();
	var _g = [];
	var _g1 = 0;
	while(_g1 < ns.length) {
		var n = ns[_g1];
		++_g1;
		_g.push(n.node);
	}
	return new hyperfiddle_hx_View(tmp,new hyperfiddle_hx_Push(_g,hyperfiddle_hx_NodeDef.ApplyAsync(f)));
};
var hyperfiddle_hx_View = function(f,n) {
	this.F = f;
	this.node = n;
};
hyperfiddle_hx_View.__name__ = true;
var hyperfiddle_hx_Input = function(f,n) {
	hyperfiddle_hx_View.call(this,f,n);
};
hyperfiddle_hx_Input.__name__ = true;
hyperfiddle_hx_Input.__super__ = hyperfiddle_hx_View;
hyperfiddle_hx_Input.prototype = $extend(hyperfiddle_hx_View.prototype,{
	put: function(a) {
		this.F.put(this.node,hyperfiddle_hx_Action.Val(a));
	}
	,end: function() {
		this.F.put(this.node,hyperfiddle_hx_Action.End);
	}
});
var hyperfiddle_hx_Output = function(f,n) {
	hyperfiddle_hx_View.call(this,f,n);
};
hyperfiddle_hx_Output.__name__ = true;
hyperfiddle_hx_Output.__super__ = hyperfiddle_hx_View;
hyperfiddle_hx_Output.prototype = $extend(hyperfiddle_hx_View.prototype,{
	init: function() {
		this.F.update(this.node);
	}
	,off: function() {
		this.F.put(this.node,hyperfiddle_hx_Action.End);
	}
});
var hyperfiddle_hx_NodeDef = $hxEnums["hyperfiddle.hx.NodeDef"] = { __ename__ : true, __constructs__ : ["From","Into","ApplyN","ApplyAsync"]
	,From: ($_=function(source) { return {_hx_index:0,source:source,__enum__:"hyperfiddle.hx.NodeDef",toString:$estr}; },$_.__params__ = ["source"],$_)
	,Into: ($_=function(f) { return {_hx_index:1,f:f,__enum__:"hyperfiddle.hx.NodeDef",toString:$estr}; },$_.__params__ = ["f"],$_)
	,ApplyN: ($_=function(f) { return {_hx_index:2,f:f,__enum__:"hyperfiddle.hx.NodeDef",toString:$estr}; },$_.__params__ = ["f"],$_)
	,ApplyAsync: ($_=function(f) { return {_hx_index:3,f:f,__enum__:"hyperfiddle.hx.NodeDef",toString:$estr}; },$_.__params__ = ["f"],$_)
};
var hyperfiddle_hx_Action = $hxEnums["hyperfiddle.hx.Action"] = { __ename__ : true, __constructs__ : ["Val","Error","End"]
	,Val: ($_=function(a) { return {_hx_index:0,a:a,__enum__:"hyperfiddle.hx.Action",toString:$estr}; },$_.__params__ = ["a"],$_)
	,Error: ($_=function(e) { return {_hx_index:1,e:e,__enum__:"hyperfiddle.hx.Action",toString:$estr}; },$_.__params__ = ["e"],$_)
	,End: {_hx_index:2,__enum__:"hyperfiddle.hx.Action",toString:$estr}
};
var hyperfiddle_hx_Maybe = $hxEnums["hyperfiddle.hx.Maybe"] = { __ename__ : true, __constructs__ : ["Just","Nothing"]
	,Just: ($_=function(a) { return {_hx_index:0,a:a,__enum__:"hyperfiddle.hx.Maybe",toString:$estr}; },$_.__params__ = ["a"],$_)
	,Nothing: {_hx_index:1,__enum__:"hyperfiddle.hx.Maybe",toString:$estr}
};
var hyperfiddle_hx_Flow = function() {
	this.queue = [];
	this.frame = 0;
	this.lock = false;
};
hyperfiddle_hx_Flow.__name__ = true;
hyperfiddle_hx_Flow.id = function() {
	return ++hyperfiddle_hx_Flow.count;
};
hyperfiddle_hx_Flow.prototype = {
	put: function(node,a) {
		node.put(a);
		var node1 = node.unlink();
		if(node1 != null) {
			this.add(node1);
		}
		this.run();
	}
	,all: function(f) {
		this.lock = true;
		var e = null;
		try {
			f();
		} catch( _g ) {
			var x = haxe_Exception.caught(_g);
			e = x;
		}
		this.lock = false;
		this.run();
		this.onError(e);
	}
	,add: function(node) {
		if(node.queued) {
			return;
		}
		while(this.queue.length <= node.rank) this.queue.push([]);
		this.queue[node.rank].push(node);
		node.queued = true;
	}
	,run: function() {
		if(this.lock) {
			return;
		}
		this.lock = true;
		this.frame++;
		var e = null;
		try {
			var rank = 0;
			while(rank < this.queue.length) {
				var _g = 0;
				var _g1 = this.queue[rank];
				while(_g < _g1.length) {
					var node = _g1[_g];
					++_g;
					node.run(this);
				}
				var _g2 = 0;
				var _g3 = this.queue[rank];
				while(_g2 < _g3.length) {
					var node1 = _g3[_g2];
					++_g2;
					var node2 = node1.unlink();
					while(node2 != null) {
						if(node2.rank == rank) {
							node2.run(this);
						} else if(node2.rank > rank) {
							this.add(node2);
						}
						node2 = node2.unlink();
					}
				}
				var _g4 = 0;
				var _g5 = this.queue[rank];
				while(_g4 < _g5.length) {
					var node3 = _g5[_g4];
					++_g4;
					this.clear(node3);
				}
				this.queue[rank].length = 0;
				++rank;
			}
		} catch( _g ) {
			var x = haxe_Exception.caught(_g);
			e = x;
		}
		this.lock = false;
		this.onError(e);
	}
	,into: function(n,f,val) {
		f(val);
	}
	,onError: function(e) {
		if(e != null) {
			hyperfiddle_hx_Origin.onError(e);
		}
	}
	,clear: function(n) {
		if(!n.queued) {
			return;
		}
		n.queued = false;
		var x = n.to;
		if(!Lambda.exists(x != null ? x : [],function(x) {
			return x.joins();
		})) {
			n.val = hyperfiddle_hx_Maybe.Nothing;
		}
		var x = n.on;
		var x1 = $getIterator((x != null ? x : []));
		while(x1.hasNext()) {
			var x = x1.next();
			this.clear(x);
		}
	}
	,update: function(a) {
		if(!a.active()) {
			return;
		}
		a.rank = 0;
		var x = a.on;
		var x1 = $getIterator((x != null ? x : []));
		while(x1.hasNext()) {
			var x = x1.next();
			this.attach(x,a);
			if(x.rank > a.rank) {
				a.rank = x.rank;
			}
		}
		if(a.joins()) {
			a.rank++;
		}
	}
	,attach: function(a,b) {
		if(a.to == null) {
			a.to = [b];
			var _g = a.def;
			if(_g._hx_index == 0) {
				var source = _g.source;
				if(source.on != null) {
					source.on();
				}
			}
		} else if(!Lambda.has(a.to,b)) {
			a.to.push(b);
		}
		this.update(a);
	}
	,detach: function(a,b) {
		if(a.to != null && HxOverrides.remove(a.to,b)) {
			var x = a.to;
			if(x == null || x.length == 0) {
				var _g = a.def;
				if(_g._hx_index == 0) {
					var source = _g.source;
					if(source.off != null) {
						source.off();
					}
				} else {
					var x = a.on;
					var x1 = $getIterator((x != null ? x : []));
					while(x1.hasNext()) {
						var x = x1.next();
						this.detach(x,a);
					}
				}
			}
		}
	}
};
var hyperfiddle_hx_Push = function(ns,d) {
	this.ended = false;
	this.val = hyperfiddle_hx_Maybe.Nothing;
	this.queued = false;
	this.frame = 0;
	this.rank = 0;
	this.id = hyperfiddle_hx_Flow.id();
	this.def = d;
	if(ns != null) {
		this.on = ns.slice();
	}
};
hyperfiddle_hx_Push.__name__ = true;
hyperfiddle_hx_Push.prototype = {
	toString: function() {
		return "Push(" + this.id + ", " + this.rank + ", " + Std.string(this.def) + ")";
	}
	,ok: function() {
		if(this.val != hyperfiddle_hx_Maybe.Nothing) {
			return !this.ended;
		} else {
			return false;
		}
	}
	,active: function() {
		if(!this.ended) {
			if(this.to == null) {
				var _g = this.def;
				if(_g._hx_index == 1) {
					var _g1 = _g.f;
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		} else {
			return false;
		}
	}
	,joins: function() {
		if(this.on != null) {
			return this.on.length > 1;
		} else {
			return false;
		}
	}
	,extract: function(a) {
		switch(a._hx_index) {
		case 0:
			var a1 = a.a;
			return a1;
		case 1:
			return null;
		}
	}
	,run: function(F) {
		var _gthis = this;
		if(this.frame == F.frame) {
			return;
		}
		this.frame = F.frame;
		if(!this.active()) {
			return;
		}
		var _g = this.def;
		switch(_g._hx_index) {
		case 0:
			var _g1 = _g.source;
			break;
		case 1:
			var _g1 = _g.f;
			if(this.on != null && Lambda.foreach(this.on,function(n) {
				return n.ok();
			})) {
				var _g1 = this.def;
				switch(_g1._hx_index) {
				case 1:
					var f = _g1.f;
					F.into(this,f,this.extract(this.on[0].val));
					break;
				case 2:
					var f = _g1.f;
					try {
						var _g2 = [];
						var _g3 = 0;
						var _g4 = this.on;
						while(_g3 < _g4.length) {
							var n = _g4[_g3];
							++_g3;
							_g2.push(this.extract(n.val));
						}
						this.put(hyperfiddle_hx_Action.Val(f(_g2)));
					} catch( _g2 ) {
						var e = haxe_Exception.caught(_g2).unwrap();
						this.put(hyperfiddle_hx_Action.Error(e));
					}
					break;
				case 3:
					var f = _g1.f;
					var _g1 = [];
					var _g2 = 0;
					var _g3 = this.on;
					while(_g2 < _g3.length) {
						var n = _g3[_g2];
						++_g2;
						_g1.push(this.extract(n.val));
					}
					f(_g1,function(err) {
						F.put(_gthis,hyperfiddle_hx_Action.Error(err));
					},function(v) {
						F.put(_gthis,hyperfiddle_hx_Action.Val(v));
					});
					break;
				default:
				}
			} else {
				var x = this.on;
				if(Lambda.exists(x != null ? x : [],function(n) {
					return n.ended;
				})) {
					this.put(hyperfiddle_hx_Action.End);
				}
			}
			try {
				var x = this.on;
				var x1 = $getIterator((x != null ? x : []));
				while(x1.hasNext()) {
					var x = x1.next();
					if(x.error != null) {
						throw haxe_Exception.thrown(x.error);
					}
				}
			} catch( _g1 ) {
				var e = haxe_Exception.caught(_g1).unwrap();
				this.put(hyperfiddle_hx_Action.Error(e));
			}
			break;
		case 2:
			var _g1 = _g.f;
			if(this.on != null && Lambda.foreach(this.on,function(n) {
				return n.ok();
			})) {
				var _g1 = this.def;
				switch(_g1._hx_index) {
				case 1:
					var f = _g1.f;
					F.into(this,f,this.extract(this.on[0].val));
					break;
				case 2:
					var f = _g1.f;
					try {
						var _g2 = [];
						var _g3 = 0;
						var _g4 = this.on;
						while(_g3 < _g4.length) {
							var n = _g4[_g3];
							++_g3;
							_g2.push(this.extract(n.val));
						}
						this.put(hyperfiddle_hx_Action.Val(f(_g2)));
					} catch( _g2 ) {
						var e = haxe_Exception.caught(_g2).unwrap();
						this.put(hyperfiddle_hx_Action.Error(e));
					}
					break;
				case 3:
					var f = _g1.f;
					var _g1 = [];
					var _g2 = 0;
					var _g3 = this.on;
					while(_g2 < _g3.length) {
						var n = _g3[_g2];
						++_g2;
						_g1.push(this.extract(n.val));
					}
					f(_g1,function(err) {
						F.put(_gthis,hyperfiddle_hx_Action.Error(err));
					},function(v) {
						F.put(_gthis,hyperfiddle_hx_Action.Val(v));
					});
					break;
				default:
				}
			} else {
				var x = this.on;
				if(Lambda.exists(x != null ? x : [],function(n) {
					return n.ended;
				})) {
					this.put(hyperfiddle_hx_Action.End);
				}
			}
			try {
				var x = this.on;
				var x1 = $getIterator((x != null ? x : []));
				while(x1.hasNext()) {
					var x = x1.next();
					if(x.error != null) {
						throw haxe_Exception.thrown(x.error);
					}
				}
			} catch( _g1 ) {
				var e = haxe_Exception.caught(_g1).unwrap();
				this.put(hyperfiddle_hx_Action.Error(e));
			}
			break;
		case 3:
			var _g1 = _g.f;
			if(this.on != null && Lambda.foreach(this.on,function(n) {
				return n.ok();
			})) {
				var _g = this.def;
				switch(_g._hx_index) {
				case 1:
					var f = _g.f;
					F.into(this,f,this.extract(this.on[0].val));
					break;
				case 2:
					var f = _g.f;
					try {
						var _g1 = [];
						var _g2 = 0;
						var _g3 = this.on;
						while(_g2 < _g3.length) {
							var n = _g3[_g2];
							++_g2;
							_g1.push(this.extract(n.val));
						}
						this.put(hyperfiddle_hx_Action.Val(f(_g1)));
					} catch( _g1 ) {
						var e = haxe_Exception.caught(_g1).unwrap();
						this.put(hyperfiddle_hx_Action.Error(e));
					}
					break;
				case 3:
					var f = _g.f;
					var _g = [];
					var _g1 = 0;
					var _g2 = this.on;
					while(_g1 < _g2.length) {
						var n = _g2[_g1];
						++_g1;
						_g.push(this.extract(n.val));
					}
					f(_g,function(err) {
						F.put(_gthis,hyperfiddle_hx_Action.Error(err));
					},function(v) {
						F.put(_gthis,hyperfiddle_hx_Action.Val(v));
					});
					break;
				default:
				}
			} else {
				var x = this.on;
				if(Lambda.exists(x != null ? x : [],function(n) {
					return n.ended;
				})) {
					this.put(hyperfiddle_hx_Action.End);
				}
			}
			try {
				var x = this.on;
				var x1 = $getIterator((x != null ? x : []));
				while(x1.hasNext()) {
					var x = x1.next();
					if(x.error != null) {
						throw haxe_Exception.thrown(x.error);
					}
				}
			} catch( _g ) {
				var e = haxe_Exception.caught(_g).unwrap();
				this.put(hyperfiddle_hx_Action.Error(e));
			}
			break;
		}
	}
	,put: function(a) {
		switch(a._hx_index) {
		case 0:
			var v = a.a;
			this.val = hyperfiddle_hx_Maybe.Just(v);
			break;
		case 1:
			var e = a.e;
			this.error = e;
			break;
		case 2:
			this.ended = true;
			break;
		}
		this.push();
	}
	,push: function() {
		if(this.to == null) {
			return;
		}
		var n = this;
		var _g = 0;
		var _g1 = this.to;
		while(_g < _g1.length) {
			var x = _g1[_g];
			++_g;
			if(x.queued) {
				continue;
			}
			n.link(x);
			n = x;
		}
	}
	,link: function(n) {
		if(n == this.next) {
			return;
		}
		n.unlink();
		n.next = this.next;
		n.prev = this;
		this.next = n;
	}
	,unlink: function() {
		if(this.prev != null) {
			this.prev.next = this.next;
		}
		if(this.next != null) {
			this.next.prev = this.prev;
		}
		var n = this.next;
		this.prev = null;
		this.next = null;
		return n;
	}
};
var hyperfiddle_hx_Meta = function() { };
hyperfiddle_hx_Meta.__name__ = true;
var hyperfiddle_hx_X = function() { };
hyperfiddle_hx_X.__name__ = true;
hyperfiddle_hx_X.ok = function(x) {
	return x != null;
};
hyperfiddle_hx_X.assume = function(x) {
	return x;
};
hyperfiddle_hx_X.check = function(x) {
	if(x != null) {
		throw haxe_Exception.thrown(x);
	}
};
hyperfiddle_hx_X.nil = function(x) {
	if(x != null) {
		return x.length == 0;
	} else {
		return true;
	}
};
hyperfiddle_hx_X.opt = function(x) {
	if(x != null) {
		return x;
	} else {
		return [];
	}
};
var js_Boot = function() { };
js_Boot.__name__ = true;
js_Boot.__string_rec = function(o,s) {
	if(o == null) {
		return "null";
	}
	if(s.length >= 5) {
		return "<...>";
	}
	var t = typeof(o);
	if(t == "function" && (o.__name__ || o.__ename__)) {
		t = "object";
	}
	switch(t) {
	case "function":
		return "<function>";
	case "object":
		if(o.__enum__) {
			var e = $hxEnums[o.__enum__];
			var n = e.__constructs__[o._hx_index];
			var con = e[n];
			if(con.__params__) {
				s = s + "\t";
				return n + "(" + ((function($this) {
					var $r;
					var _g = [];
					{
						var _g1 = 0;
						var _g2 = con.__params__;
						while(true) {
							if(!(_g1 < _g2.length)) {
								break;
							}
							var p = _g2[_g1];
							_g1 = _g1 + 1;
							_g.push(js_Boot.__string_rec(o[p],s));
						}
					}
					$r = _g;
					return $r;
				}(this))).join(",") + ")";
			} else {
				return n;
			}
		}
		if(((o) instanceof Array)) {
			var str = "[";
			s += "\t";
			var _g = 0;
			var _g1 = o.length;
			while(_g < _g1) {
				var i = _g++;
				str += (i > 0 ? "," : "") + js_Boot.__string_rec(o[i],s);
			}
			str += "]";
			return str;
		}
		var tostr;
		try {
			tostr = o.toString;
		} catch( _g ) {
			return "???";
		}
		if(tostr != null && tostr != Object.toString && typeof(tostr) == "function") {
			var s2 = o.toString();
			if(s2 != "[object Object]") {
				return s2;
			}
		}
		var str = "{\n";
		s += "\t";
		var hasp = o.hasOwnProperty != null;
		var k = null;
		for( k in o ) {
		if(hasp && !o.hasOwnProperty(k)) {
			continue;
		}
		if(k == "prototype" || k == "__class__" || k == "__super__" || k == "__interfaces__" || k == "__properties__") {
			continue;
		}
		if(str.length != 2) {
			str += ", \n";
		}
		str += s + k + " : " + js_Boot.__string_rec(o[k],s);
		}
		s = s.substring(1);
		str += "\n" + s + "}";
		return str;
	case "string":
		return o;
	default:
		return String(o);
	}
};
function $getIterator(o) { if( o instanceof Array ) return new haxe_iterators_ArrayIterator(o); else return o.iterator(); }
if(typeof(performance) != "undefined" ? typeof(performance.now) == "function" : false) {
	HxOverrides.now = performance.now.bind(performance);
}
String.__name__ = true;
Array.__name__ = true;
js_Boot.__toStr = ({ }).toString;
hyperfiddle_hx_Flow.count = 0;
})({});
