// Todo not sure if all loaded signatures will be the same after switching Webpack -> Parcel

window.require = require

require('bluebird')
require('xregexp')

window.propTypes = require('prop-types');
window.React = require("react");
window.createReactClass = require("create-react-class");
window.ReactDOM = require("react-dom");

// window.remark = require('remark');            // todo need browser shim for path (path-browserify)
// window.remarkComments = require('remark-comments');
// window.remarkGenericExtensions = require('@hyperfiddle/remark-generic-extensions/lib/browser');
// window.remarkReact = require('remark-react'); // todo npm deps chokes on hast-util-sanitize/lib/github.json
// window.remarkToc = require('remark-toc');

window.CodeMirror = require("codemirror");
// window.Raphael = require('raphael'); // for parinfer
// window.parinferCodeMirror = require('parinfer-codemirror');

window.keypress = require('keypress.js/keypress-2.1.5.min').keypress; // todo remove; ide

// window.ReactVirtualized = require('react-virtualized');
// require('react-virtualized/styles.css');
window.ReactBootstrapTypeahead = require('react-bootstrap-typeahead');

/* Codemirror will default to the first mode loaded */
require("codemirror/mode/clojure/clojure");
// require("codemirror/mode/css/css");
// require("codemirror/mode/markdown/markdown");
require("codemirror/addon/edit/closebrackets");
require("codemirror/addon/edit/matchbrackets");
require('codemirror/lib/codemirror.css');
require('bootstrap/dist/css/bootstrap.css');
//require('./re-com/css/material-design-iconic-font.min.css');
require('./re-com/css/re-com.css');

// ---- hyperfiddle platform
require('./dataflow.js');
require('./body.css'); /* undo damage from recom */
require('./typography.css');
require('./recom-hacks.css');
require('./util.css');
require('./controls.css');
require('./typeahead.css');
require('./date-picker.css');
require('./form.css');
require('./table.css');
require('./label.css');
require('./logo.css');
require('./loading.css');
require('./hyperfiddle.css');
require('./hyperfiddle-foundation.css');
require('./stage.css');
require('./system-fiddles.css');

// ---- userland
require('./swinged_rosie/rosie.css');
